var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.componentKey,staticClass:"room flex flex-none",style:({ minWidth: _vm.minWidth, width: _vm.minWidth })},[_c('div',{staticClass:"flex flex-row",staticStyle:{"width":"100% !important"}},_vm._l((this.numBlocks),function(v,i){return _c('div',{key:("room-" + _vm.id + "-block-" + i),staticClass:"block",style:({ backgroundColor: _vm.theme })},[(
          _vm.occupied
          && !!_vm.filteredTreatments[i]
          && !!_vm.filteredTreatments[i].treatment
        )?_c('TreatmentCard',{attrs:{"id":_vm.filteredTreatments[i].uuid,"treatment":_vm.filteredTreatments[i],"room":_vm.room,"appointment":_vm.appointment,"appointment-id":_vm.appointment.uuid,"disabled":_vm.activeTreatmentId && !(_vm.filteredTreatments[i].uuid === _vm.activeTreatmentId),"initials":_vm._f("initials")(_vm.filteredTreatments[i].doctor.name),"initialsColor":_vm.filteredTreatments[i].doctor.color,"room-id":_vm.id,"title":_vm.filteredTreatments[i].treatment.name,"initial-allocated-time":_vm.filteredTreatments[i].duration * 1000,"started-at":_vm.filteredTreatments[i].started_at,"ended-at":_vm.filteredTreatments[i].ended_at}}):_vm._e(),(_vm.occupied && (i + 1) === _vm.filteredTreatments.length && !!_vm.filteredTreatments[i] !== undefined && _vm.fitsTheFilter)?_c('v-card',{staticClass:"gray-tile add-button",on:{"click":function($event){return _vm.$store.dispatch(
          'selectAppointment',
          { appointment: Object.assign({}, _vm.appointment, {room: _vm.room})
        })}}},[_vm._v(" + ")]):_vm._e()],1)}),0),_c('div',{staticClass:"block block--final",style:({ backgroundColor: _vm.theme })},[(_vm.occupied && _vm.showTimer)?_c('TimerCard',{attrs:{"appointment-state":"inactive","appointment":_vm.appointment,"number-of-treatments":_vm.appointment.treatments.length,"room-id":_vm.id,"is-disabled":!_vm.fitsTheFilter},on:{"click":function($event){_vm.fitsTheFilter ? _vm.$store.dispatch('selectAppointment', { appointment: _vm.appointment }) : null}}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }