
import Vue from "vue";
import ExtendedDoctorCard from "@/components/cards/ExtendedDoctorCard.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "SelectTime",
  components: { ExtendedDoctorCard },
  props: {
    selectedDoctor: {
      type: Object,
      required: true,
    },
    selectedPatientType: {
      type: String,
      required: true,
    },
    selectedAppointmentType: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      slots: [],
      slotsKeys: [],
      firstDate: undefined,
      lastDate: undefined,
      docBookingObjects: [],
      selectedDoctorLocationId: this.selectedDoctor?.locationId,
      selectedDoctorAppointmentType: this.selectedAppointmentType,
    };
  },
  created() {
    this.setSlots(this.selectedDoctor?.slots);
    this.setDates();

    const hash = this.$route.hash ? this.$route.hash.substring(1) : null;
    if (hash) {
      this.scrollToDate(hash);
    } else {
      this.scrollToTop();
    }
  },
  mounted() {
    this.docBookingObjects = this.getDoctorBooking(this.selectedDoctor?.externalId);
    this.selectedDoctorAppointmentType = this.selectedAppointmentType;
  },
  watch: {
    selectedDoctor() {
      this.docBookingObjects = this.getDoctorBooking(this.selectedDoctor?.externalId);
      this.setSlots(this.selectedDoctor?.slots);
    },
    slots: "setDates",
    bookingTemplates() {
      this.docBookingObjects = this.getDoctorBooking(this.selectedDoctor?.externalId);
    },
    selectedDoctorLocationId() {
      console.log(this.selectedDoctorLocationId);
      if (!!this.selectedDoctorLocationId && this.selectedDoctorLocationId !== this.selectedDoctor.locationId) {
        this.$emit("set-selected-location", this.selectedDoctorLocationId);

        const docBookingObj = this.docBookingObjects.find(
          (doc) =>
              doc.locationId === this.selectedDoctorLocationId &&
              doc.templateType === this.selectedPatientType &&
              doc.templatePublicName === this.selectedAppointmentType
        );

        const doc = this.getDoctorObject(docBookingObj);
        this.$emit("set-selected-doctor", doc);
      } else if (!!this.selectedDoctorLocationId) {
        this.selectedDoctorLocationId = undefined;
      }
    },
    selectedDoctorAppointmentType() {
      if (!!this.selectedDoctorAppointmentType && this.selectedDoctorAppointmentType !== this.selectedAppointmentType) {

        const docBookingObjs = this.docBookingObjects.filter(
            (doc) =>
                doc.templatePublicName === this.selectedAppointmentType &&
                doc.templateType === this.selectedPatientType
        );

        let docObj;

        const matchingLocation = docBookingObjs.find(
            ({ locationId }) => locationId === this.selectedDoctor.locationId
        );

        if (matchingLocation) {
          docObj = matchingLocation;
        } else if (docBookingObjs.length > 0) {
          docObj = docBookingObjs[0];
        } else {
          console.warn("No matching booking objects found.");
          return;
        }

        const doc = this.getDoctorObject(docObj);
        this.$emit("set-selected-appt-type", this.selectedDoctorAppointmentType);
        this.$emit("set-selected-location", docObj.locationId);
        this.$emit("set-selected-doctor", doc);
      } else if (!!this.selectedDoctorAppointmentType) {
        this.selectedDoctorAppointmentType = undefined;
      }
    },
    "$route.hash": function () {
      const hash = this.$route.hash ? this.$route.hash.substring(1) : null;
      if (hash) {
        this.scrollToDate(hash);
      } else {
        this.scrollToTop();
      }
    },
  },
  computed: {
    ...mapGetters(["bookingLoading", "bookingTemplates", "bookingLocations", 'getDoctorBooking']),
    nextDates() {
      const currentLastDate = this.lastDate.object.clone();
      const start = currentLastDate.add(1, "day");
      const end = start.add(7, "day");

      return {
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      };
    },
    prevDates() {
      const currentFirstDate = this.firstDate?.object.clone();
      const today = this.$dayjs.tz().startOf('day');

      if (!currentFirstDate) {
        return { start: null, end: null };
      }

      let end = currentFirstDate.subtract(1, "day");
      let start = end.subtract(7, "day");

      if (start.isBefore(today)) {
        start = today;
      }

      if (end.isBefore(today)) {
        return { start: null, end: null };
      }

      return {
        start: start.format("YYYY-MM-DD"),
        end: end.format("YYYY-MM-DD"),
      };
    },
    isPrevWeekDisabled() {
      return !this.prevDates?.start && !this.prevDates?.end;
    },
    sameDoctorsLocationList() {
      const locations = new Map();

      this.docBookingObjects
          .map((doc) => {
        locations.set(doc.locationId, {
          name: `${doc.locationAddress} - ${doc.location}`,
          locationId: doc.locationId,
        });
      });

      return [...new Set(locations.values())];
    },
    sameDoctorServiceList() {
      const service = new Map();

      this.docBookingObjects
          .filter(({templateType}) => templateType === this.selectedPatientType)
          .map((doc) => {
            service.set(doc.templatePublicName, {
              name: `${doc.templatePublicName}`,
            });
          });

      return [...new Set(service.values())];
    },
    hasAnotherLocations() {
      return !!this.sameDoctorsLocationList && this.sameDoctorsLocationList.length > 0;
    },
    hasAnotherService() {
      return !!this.sameDoctorServiceList && this.sameDoctorServiceList.length > 0;
    },
  },
  methods: {
    scrollToDate(date) {
      this.$nextTick(() => {
        const dateContainer = this.$refs[`date-${date}`];
        if (dateContainer && dateContainer[0]) {
          dateContainer[0].scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    getDoctorObject(bookingDocObject) {
      const template = this.bookingTemplates.find(
          ({ id }) => id === bookingDocObject.templateId
      );
      const location = this.bookingLocations.find(
          ({ id }) => id === template.location_id
      );

      return {
        specialty: bookingDocObject.specialty,
        uuid: bookingDocObject.uuid,
        reviewWidgetClass: bookingDocObject.review_widget_class,
        templateId: bookingDocObject.templateId,
        externalId: bookingDocObject.externalId,
        name: bookingDocObject.name,
        shortBio: bookingDocObject.shortBio,
        avatar: bookingDocObject.avatar,
        slots: bookingDocObject.slots,
        location: location?.address,
        phoneNumber: location?.phone_numbers,
        locationId: location?.id ?? null,
        locationName:
            location?.short_public_name ?? location?.name ?? null,
        locationPosition: location?.position ?? null,
      };
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    selectTime(date: String, time: String) {
      const dateTimeStr = `${date} ${time}`;
      const timeObj = this.$dayjs
        .tz(dateTimeStr, "YYYY-MM-DD h:mm A", "US/Eastern")
        .toISOString();

      this.$emit("set-selected-time", timeObj);
    },
    setSlots(slots) {
      this.slotsKeys = Object.keys(slots);

      const slotsArrKeys = this.slotsKeys;

      this.slots = slotsArrKeys.map((day) => {
        return {
          dateLabel: this.$dayjs(day).format("dddd, MMMM D"),
          date: this.$dayjs(day).format("YYYY-MM-DD"),
          times: slots[day],
          timesCount: slots[day].length,
        };
      });
    },
    nextWeekSlots() {
      this.requestSlots(this.nextDates.start, this.nextDates.end);
    },
    prevWeekSlots() {
      this.requestSlots(this.prevDates.start, this.prevDates.end);
    },
    async requestSlots(startDate, endDate) {
      const payload = {
        data: {
          doctor_external_id: this.selectedDoctor?.externalId,
          template_id: this.selectedDoctor?.templateId,
          startDate: startDate,
          endDate: endDate,
        },
      };

      const slots = await this.$store.dispatch(
        "LOAD_SLOTS_FOR_DOCTOR",
        payload
      );

      if (!!slots) {
        this.setSlots(slots);
      }
    },
    setDates() {
      let firstDate = null;
      let lastDate = null;

      if (this.slots.length > 0) {
        firstDate = this.$dayjs(this.slots[0].date);
        lastDate = this.$dayjs(this.slots[this.slots.length - 1].date);

        this.firstDate = {
          label: firstDate.format("D MMMM"),
          object: firstDate.startOf('day'),
        };

        this.lastDate = {
          label: lastDate.format("D MMMM"),
          object: lastDate.startOf('day'),
        };
      }
    },
  },
});
