




























































import TimerCard from '@/components/cards/TimerCard.vue';
import TreatmentCard from '@/components/cards/TreatmentCard.vue';
import Vue from 'vue';
import {toInteger} from 'lodash';

export default Vue.extend({
  name: 'Room',
  components: { TimerCard, TreatmentCard },
  props: {
    id: {type: String, required: true},
    room: {type: Object, default: null },
    theme: {type: String, default: '#C9D4E2'},
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      activeTreatmentId: null,
      componentKey: 0,
      // showAppointment: true,
      showTimer: true
    }
  },
  computed: {
    numBlocks() {
      const minBlocsCount = 4;
      const actualCount = toInteger((this.windowWidth - 264) / 112) - 1
      return actualCount > minBlocsCount ? actualCount : minBlocsCount;
    },
    numBlocksArray() {
      return [...Array(this.numBlocks).keys()]
    },
    minWidth() {
      const px = 112 * (this.numBlocks + 1);
      return `${px - 88}px`;
    },
    occupied() {
      return !!this.appointment && !!this.appointment.uuid;
    },
    filter() {
      return this.$store.state.filter;
    },
    fitsTheFilter() {
      let fitsTheFilter = false;
      this.appointment.treatments.some( t => {
        if (t.doctor.uuid === this.filter) {
          fitsTheFilter = true
          return false
        }
      })

      return !!this.filter ? fitsTheFilter : true
    },
    filteredTreatments() {

      let ts = this.appointment.treatments;
      if (!this.filter && this.occupied) this.showTimer = true;

      const complete = this.appointment.treatments.filter((t: { status: string; }) => ['completed'].includes(t.status));
      const incomplete = this.appointment
          .treatments.filter(t => !t.ended_at)
          .filter((t: { status: string; }) => !['paused','processing'].includes(t.status));
      const active = this.appointment.treatments.filter( (t: { status: string; }) => ['paused','processing'].includes(t.status))
      let sorted = [...active,...incomplete, ...this.$_.orderBy(complete, 'ended_at')];

      // because indexing gets messed up when you filter, so if you start a treatment and then filter, then
      // the treatment in the same index will show up as having been started
      sorted = sorted.map(t => {
        if (ts.includes(t)) {
          return t;
        } else {
          return undefined;
        }
      })

      return sorted;
    },
    appointment() {
      return this.room.appointments.find(({ status: x }) =>
        ['processing', 'paused'].includes(x)
      ) || null;
    }
  },
  created(): void {
    this.$eventBus.$on(`room-${this.id}-state`, this.setActiveTreatmentId);
  },
  mounted(): void {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeDestroy(): void {
    this.$eventBus.$off(`room-${this.roomId}-state`);
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    setActiveTreatmentId(payload: any) {
      console.log('Setting active Treatment id:', { payload })
      const { state, treatmentId } = payload;
      const activeStates = ['active', 'warning', 'overtime'];
      if (activeStates.includes(state)) {
        this.activeTreatmentId = treatmentId;
      } else {
        this.activeTreatmentId = null;
      }
    },
    // filterAppointment() {
    //   console.log(this.filter);
    //   console.log(this.appointment);

    //   const filtered = (t) => {
    //     if (t.doctor) {
    //       if (t.doctor.uuid === this.filter) {
    //         return true;
    //       }
    //     }
    //     return false;
    //   }

    //   if (this.appointment) {
    //     if (this.filter) {
    //       if (this.appointment.doctor) {
    //         if (this.appointment.doctor.uuid === this.filter) {
    //           this.showAppointment = true;
    //         } else {
    //           this.showAppointment = this.appointment.treatments.find(t => filtered(t)) ? true : false;
    //         }
    //       } else {
    //         this.showAppointment = this.appointment.treatments.find(t => filtered(t)) ? true : false;
    //       }
    //     } else {
    //       this.showAppointment = true;
    //     }
    //   }
    //   console.log(this.showAppointment);
    // }
  }
});
